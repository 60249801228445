import { Col, Row } from 'antd';

import React from 'react';

function KnowledgeBasePage() {
  document.title = 'Knowledge Base - Hortivar'
  return (
    <>
        <Row className="content">
            <Col className="sidebar" span={5}></Col>
            <Col span={14} className="error-pane">
                <h1 className="not-found-error">503</h1>
                <p className="p-not-found-text">This page is currently under development and will be coming soon. </p>
                <p className="p-not-found-text">Please check again later.
                </p>
            </Col>
            <Col className="sidebar" span={5}></Col>
        </Row>
      </>
  );
}

export default KnowledgeBasePage;