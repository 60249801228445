import { AutoComplete, Input, message } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SearchOutlined } from '@ant-design/icons'
const { Search } = Input

const SearchBar = () => {
   const history = useHistory()

   const [options] = useState([])

   const handleSearch = (value) => {
      if (value.length !== 0) {
         history.push(`/search/${value}`)
      } else {
         message.error(
            'ERROR: Your search query cannot be empty. Please type in a query and try again.'
         )
      }
   }

   return (
      <>
         <div className="subnav-search">
            <AutoComplete
               options={options}
               // dropdownMatchSelectWidth={500}
               style={{ width: 550 }}
               className="subnav-search-bar"
            >
               <Search
                  addonBefore="Search"
                  placeholder="Search by cultivar name.."
                  onSearch={handleSearch}
                  enterButton={<SearchOutlined />}
                  allowClear={true}
                  size="large"
               />
            </AutoComplete>
         </div>
      </>
   )
}

export default SearchBar
