import { Alert, Button, Col, Input, Row } from 'antd'
import { LoginOutlined, UserOutlined } from '@ant-design/icons'

import ImageSlider from '../components/login/ImageSlider'
import { Link } from 'react-router-dom'
import { ReactComponent as LoginLogo } from '../assets/images/login_logo.svg'
import React from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../components/auth'
import { withRouter } from 'react-router-dom'

const INITIAL_STATE = {
   email: '',
   password: '',
   error: null,
}

class LoginBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = { ...INITIAL_STATE }
   }

   onSubmit = (event) => {
      const { email, password } = this.state

      this.props.firebase
         .doSignInWithEmailAndPassword(email, password)
         .then(() => {
            this.setState({ ...INITIAL_STATE })
            this.props.history.push('/')
         })
         .catch((error) => {
            this.setState({ error })
         })

      event.preventDefault()
   }

   onChange = (event) => {
      this.setState({ [event.target.name]: event.target.value })
   }

   render() {
      const { email, password, error } = this.state

      const isInvalid = password === '' || email === ''

      return (
         <>
            <Row>
               <Col span={2} />
               <Col span={20}>
                  <div className="login-panel">
                     <div className="login-panel-image">
                        <ImageSlider />
                     </div>
                     <div className="login-panel-bottom">
                        <Row>
                           <Col span={12} className="login-panel-left">
                              <LoginLogo />
                              <p className="login-description">
                                 <span>Hortivar is FAO´s database</span> on the
                                 performances of horticultural cultivars over
                                 the world. Hortivar is also a platform for
                                 exchange of know-how among scientists and a
                                 tool to safeguard, retrieve and exchange
                                 information related to horticulture.
                              </p>
                           </Col>
                           <Col span={12} className="login-panel-right">
                              <form onSubmit={this.onSubmit}>
                                 {error && (
                                    <Alert
                                       message={error.message}
                                       type="warning"
                                       closable
                                    />
                                 )}
                                 <br />
                                 <Input
                                    className="cultivars-input"
                                    name="email"
                                    value={email}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Email Address"
                                 />
                                 <Input
                                    name="password"
                                    className="cultivars-input"
                                    value={password}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Password"
                                 />
                                 <br />
                                 <Button
                                    disabled={isInvalid}
                                    htmlType="submit"
                                    type="primary"
                                 >
                                    Sign In <LoginOutlined />
                                 </Button>
                                 <br />
                                 <br />
                                 <br />
                              </form>
                              <p className="forgot-password-p">
                                 Forgot Password <UserOutlined />?
                              </p>
                              <br />
                              <br />
                              <div className="register-p">
                                 <Row>
                                    <Col span={12}>
                                       <div className="para">
                                          Register for a Hortivar Account
                                       </div>
                                    </Col>
                                    <Col span={12}>
                                       <Link to="/register">
                                          <Button className="register-btn">
                                             Register <UserOutlined />
                                          </Button>
                                       </Link>
                                    </Col>
                                 </Row>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </div>
               </Col>
               <Col span={2} />
            </Row>
         </>
      )
   }
}

const Login = compose(withRouter, withFirebase)(LoginBase)

export default Login

export { Login }
