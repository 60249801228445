import { Button, Col, Row, Typography, List } from 'antd'
import { FormOutlined, EnvironmentOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'
import LoadError from '../components/LoadError'
import GMHLoading from '../components/GMHLoading'
import NoRecords from '../pages/NoRecords'
import { ReactComponent as NextLogo } from '../assets/next.svg'
import { Query } from 'react-apollo'
import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import Gravatar from 'react-gravatar'

const { Paragraph } = Typography

const ALL_POSTS_QUERY = gql`
   {
      posts {
         id
         points
         text
         title
         views
         user {
            id
            email
            firstName
            country {
               id: m49Code
               countryName
               isoAlpha2Code
            }
         }
         comments {
            id
         }
      }
   }
`

class ListGoodMorningPosts extends React.Component {
   render() {
      return (
         <>
            <Row>
               <Col className="gmh-header" span={12}>
                  <p>Good Morning HORTIVAR</p>
               </Col>
               <Col span={12}>
                  <Link to="/create-post">
                     <Button className="gmh-submit-post-btn">
                        Post a question <FormOutlined />
                     </Button>
                  </Link>
               </Col>
            </Row>
            <Query query={ALL_POSTS_QUERY} fetchPolicy={'cache-first'}>
               {({ loading, error, data }) => {
                  if (loading) return <GMHLoading />
                  if (error) return <LoadError />

                  const linksToRender = data.posts

                  if (linksToRender.length === 0) return <NoRecords />
                  return (
                     <>
                        <List
                           grid={{ column: 2 }}
                           size="large"
                           pagination={{
                              pageSize: 10,
                           }}
                           dataSource={linksToRender}
                           renderItem={(item) => (
                              <List.Item key={item.title}>
                                 <div className="gmh-post-card">
                                    <Row>
                                       <Col span={6} className="left-info">
                                          <div className="names">
                                             <p>
                                                <Gravatar
                                                   email={item.user.email}
                                                   size={30}
                                                   className="gmh-profile-logo"
                                                />
                                             </p>
                                             <p>
                                                {item.user.firstName ??
                                                   'HORTIVAR User'}
                                             </p>
                                             <span>{item.points} points</span>
                                          </div>
                                          <div className="location">
                                             <EnvironmentOutlined />{' '}
                                             {item.user.country.countryName}
                                          </div>
                                       </Col>
                                       <Col span={18} className="right-info">
                                          <Paragraph
                                             ellipsis={{
                                                rows: 2,
                                                expandable: false,
                                             }}
                                          >
                                             <p className="title">
                                                {item.title}
                                             </p>
                                          </Paragraph>
                                          <p>
                                             <span className="round-label">
                                                {item.comments.length} answers
                                             </span>
                                             <span className="round-label">
                                                {item.views} views
                                             </span>
                                             <span className="title-three gmh-time">
                                                {moment(
                                                   item.dateCreated
                                                ).format('Do MMMM YYYY')}
                                             </span>
                                             <br />
                                             <span className="round-label hash-label">
                                                #tag
                                             </span>
                                             <span className="round-label hash-label">
                                                #goodmorning
                                             </span>
                                          </p>
                                          <Paragraph
                                             ellipsis={{
                                                rows: 3,
                                                expandable: false,
                                             }}
                                          >
                                             <div
                                                className="text"
                                                dangerouslySetInnerHTML={{
                                                   __html: item.text,
                                                }}
                                             />
                                          </Paragraph>
                                          <Link to={`/view-post/${item.id}`}>
                                             <NextLogo className="next-logo" />
                                          </Link>
                                       </Col>
                                    </Row>
                                 </div>
                              </List.Item>
                           )}
                        />
                     </>
                  )
               }}
            </Query>
         </>
      )
   }
}

export default ListGoodMorningPosts
