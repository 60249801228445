import { Col, Row } from 'antd';
import CultivarsMap from '../components/TableauCultivarsMap';

import React from 'react';

function Statistics() {
  // Site default header.
  document.title = 'Hortivar - Statistics'
  return (
      <>
        <Row className="content statistics">
            <Col span={24}>
                <div>
                  <CultivarsMap/>
                </div>
            </Col>
        </Row>
      </>
  );
}

export default Statistics;