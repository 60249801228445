import { Col, Row } from 'antd'

import React from 'react'

const NotFoundPage = () => {
   document.title = 'HORTIVAR - Cultivars Performance Database'
   return (
      <>
         <Row className="content">
            <Col className="sidebar" span={5}></Col>
            <Col span={14} className="error-pane">
               <h1 className="not-found-error">404</h1>
               <p className="p-not-found-text">
                  We are sorry, but it seems we don’t have the page you are
                  requesting.{' '}
               </p>
               <p className="p-not-found-text">
                  Please search again for the content.
               </p>
            </Col>
            <Col className="sidebar" span={5}></Col>
         </Row>
      </>
   )
}

export default NotFoundPage
