import { Button, Card, Dropdown, Menu, Progress, Select, message } from 'antd';

import {CaretDownOutlined} from '@ant-design/icons';
import React from 'react';

const { Option } = Select;

function Source () {
    function handleMenuClick(e) {
        message.info('Click on menu item.');
        console.log('click', e);
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="1">
            OP Seed
          </Menu.Item>
          <Menu.Item key="2">
            2nd menu item
          </Menu.Item>
          <Menu.Item key="3">
            3rd menu item
          </Menu.Item>
        </Menu>
      );

    return (
        <>
            <Card className="cultivars-container">
              <span className="header-text">Source <span>Completion Index: <Progress type="circle" percent={90} strokeColor="#356B0C" /></span></span>
              <br/>
              <Dropdown overlay={menu}>
                <Button className="cultivars-dropdown">
                  Partner Institution <CaretDownOutlined />
                </Button>
              </Dropdown>
              <br/>
              <Dropdown overlay={menu}>
                <Button className="cultivars-dropdown">
                  Partner Seed Company <CaretDownOutlined />
                </Button>
              </Dropdown>
              <br/>
              <Dropdown overlay={menu}>
                <Button className="cultivars-dropdown">
                  FAO Project <CaretDownOutlined />
                </Button>
              </Dropdown>
              <br/>
              <span className="search-span">Search as you Type to select</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Partners"
                optionLabelProp="label"
              >
                <Option value="china" label="China">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="China">
                      🇨🇳
                    </span>
                    China (中国)
                  </div>
                </Option>
                <Option value="usa" label="USA">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="USA">
                      🇺🇸
                    </span>
                    USA (美国)
                  </div>
                </Option>
                <Option value="japan" label="Japan">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="Japan">
                      🇯🇵
                    </span>
                    Japan (日本)
                  </div>
                </Option>
                <Option value="korea" label="Korea">
                  <div className="demo-option-label-item">
                    <span role="img" aria-label="Korea">
                      🇰🇷
                    </span>
                    Korea (韩国)
                  </div>
                </Option>
              </Select>
            </Card>
        </>
    );
}

export default Source;
