import React, { Component } from 'react'
import 'tableau-api'

class CultivarsMap extends Component {
   componentDidMount() {
      this.initViz()
   }

   initViz() {
      const vizUrl =
         'https://public.tableau.com/views/HortivarEmbedStatistics/EmbedMap?:language=en&:display_count=y&publish=yes&:origin=viz_share_link'
      const vizContainer = this.vizContainer
      new window.tableau.Viz(vizContainer, vizUrl)
   }

   render() {
      return (
         <div
            ref={(div) => {
               this.vizContainer = div
            }}
         />
      )
   }
}

export default CultivarsMap
