import './index.scss'
import 'antd/dist/antd.css'
import 'react-calendar-heatmap/dist/styles.css'

import * as serviceWorker from './serviceWorker'

import Firebase, { FirebaseContext } from './components/auth/'

import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import App from './App'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider as ProviderAuth } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { createHttpLink } from 'apollo-link-http'

const httpLink = createHttpLink({
   uri: 'https://fao-gcr-hortivar-review-in25tl64ya-ey.a.run.app/graphql',
})

const client = new ApolloClient({
   link: httpLink,
   cache: new InMemoryCache(),
   onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
   },
})

ReactDOM.render(
   <>
      <ProviderAuth client={client}>
         <ApolloProvider client={client}>
            <FirebaseContext.Provider value={new Firebase()}>
               <App />
            </FirebaseContext.Provider>
         </ApolloProvider>
      </ProviderAuth>
   </>,
   document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
