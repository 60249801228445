import { Card, DatePicker, Input, Progress, Select, Tooltip } from 'antd'
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons'

import React from 'react'

const { Option } = Select

class CroppingAndYield extends React.Component {
   render() {
      return (
         <>
            <Card className="cultivars-container">
               <span className="header-text">
                  Cropping & Yield{' '}
                  <span>
                     Completion Index:{' '}
                     <Progress
                        type="circle"
                        percent={75}
                        strokeColor="#356B0C"
                     />
                  </span>
               </span>
               <br />
               <br />
               <Input
                  placeholder="Yield"
                  className="cultivars-input-50"
                  suffix={
                     <Tooltip title="Same unit as fresh yield">
                        <InfoCircleOutlined
                           style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                     </Tooltip>
                  }
                  name="unitYield"
                  onChange={this.props.handleChange}
                  value={this.props.unitYield}
               />
               <br />
               <Select
                  className="cultivars-dropdown-final"
                  placeholder="Unit"
                  onSelect={(value) =>
                     this.props.handleSelectChange(value, 'unit')
                  }
                  value={this.props.unit || 'Unit'}
                  suffixIcon={<CaretDownOutlined />}
               >
                  <Option value="0">kg/ha</Option>
                  <Option value="1">kg/m2</Option>
                  <Option value="2">kg/tree</Option>
                  <Option value="3">l/ha</Option>
                  <Option value="4">t/ha</Option>
                  <Option value="5">cuttings/tree</Option>
                  <Option value="6">flowers/plant</Option>
                  <Option value="7">flowers/m2</Option>
                  <Option value="8">stems</Option>
                  <Option value="9">stems/m2</Option>
                  <Option value="10">l/m2</Option>
                  <Option value="11">kg/t of compost</Option>
               </Select>
               <br />
               <DatePicker
                  className="cultivars-dropdown"
                  placeholder="First Harvest Date"
                  onChange={(value) =>
                     this.props.handleSelectChange(value, 'startHarvestDate')
                  }
                  value={this.props.startHarvestDate}
               />
               <br />
               <br />
               <Input
                  placeholder="First Harvest Amount"
                  className="cultivars-input-50"
                  suffix={
                     <Tooltip title="Same unit as fresh yield">
                        <InfoCircleOutlined
                           style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                     </Tooltip>
                  }
                  name="firstAmount"
                  onChange={this.props.handleChange}
                  value={this.props.firstAmount}
               />
               <br />
               <DatePicker
                  className="cultivars-dropdown"
                  placeholder="Peak Harvest Date"
                  onChange={(value) =>
                     this.props.handleSelectChange(value, 'peakHarvestDate')
                  }
                  value={this.props.peakHarvestDate}
               />
               <br />
               <br />
               <Input
                  placeholder="Peak Harvest Amount"
                  className="cultivars-input-50"
                  suffix={
                     <Tooltip title="Same unit as fresh yield">
                        <InfoCircleOutlined
                           style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                     </Tooltip>
                  }
                  name="peakAmount"
                  onChange={this.props.handleChange}
                  value={this.props.peakAmount}
               />
               <br />
               <DatePicker
                  className="cultivars-dropdown"
                  placeholder="Late Harvest Date"
                  onChange={(value) =>
                     this.props.handleSelectChange(value, 'lastHarvestDate')
                  }
                  value={this.props.lastHarvestDate}
               />
               <br />
               <br />
               <Input
                  placeholder="Last Harvest Amount"
                  className="cultivars-input-50"
                  suffix={
                     <Tooltip title="Same unit as fresh yield">
                        <InfoCircleOutlined
                           style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                     </Tooltip>
                  }
                  name="lastAmount"
                  onChange={this.props.handleChange}
                  value={this.props.lastAmount}
               />
               <br />
               <p>
                  Crop Cycle:{' '}
                  <Input
                     className="date-range-inputs"
                     name="circleValue"
                     onChange={this.props.handleChange}
                     value={this.props.circleValue}
                  />{' '}
                  days
               </p>
            </Card>
         </>
      )
   }
}

export default CroppingAndYield
