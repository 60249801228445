import React from 'react'

const LoadError = () => {
    return (
        <>  
            <div className="data-load-error">
                <span>There was an error retrieving the data. Please try again later.</span>
            </div>
        </>
    );
}

export default LoadError;