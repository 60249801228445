// Import Custom CSS to overide background on login and registration page.
// import './login.scss';

import { Button, Col, Input, Row, Select } from "antd";
import {
  CaretDownOutlined,
  LeftOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { ReactComponent as HortivarLogo } from "../assets/hortivar_logo.svg";
import { Link } from "react-router-dom";
import LoadError from "../components/LoadError";
import { ReactComponent as Logo } from "../logo.svg";
import { Query } from "react-apollo";
import { withFirebase } from "../components/auth";
import { withRouter } from "react-router-dom";

const { Option } = Select;

const CREATE_USER_MUTATION = gql`
  mutation createHortivarUser(
    $uid: String!
    $firstName: String!
    $lastName: String!
    $country: ID!
    $address: String!
    $email: String!
  ) {
    createHortivarUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      country: $country
      address: $address
      uid: $uid
    ) {
      id
      uid
    }
  }
`;

const QUERY_ALL_COUNTRIES = gql`
  {
    countries {
      id: m49Code
      countryName
    }
  }
`;

const RegisterBase = (props) => {
  document.title = "Hortivar - Performance Cultivars Database";
  const [state, setState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    uid: "",
    email: "",
    address_one: "",
    address_two: "",
    city: "",
    zip: "",
    country: null,
    passwordOne: "",
    passwordTwo: "",
    error: null,
  });

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    variables: {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      country: state.country,
      address:
        state.address_one +
        ", " +
        state.address_two +
        ", " +
        state.city +
        ", " +
        state.zip,
      uid: state.uid,
    },
  });

  const {
    firstName,
    lastName,
    email,
    address_one,
    address_two,
    city,
    zip,
    country,
    passwordOne,
    passwordTwo,
    error,
  } = state;

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    country === "" ||
    firstName === "" ||
    lastName === "" ||
    address_one === "" ||
    email === "";

  const onSubmit = (event) => {
    const { email, passwordOne } = state;

    props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        authUser.user.updateProfile({
          displayName: state.firstName + " " + state.lastName,
        });
        setState({
          ...state,
          uid: authUser.user.uid,
        });
        createUser();
        setState({ ...state });
        props.history.push("/");
      })
      .catch((error) => {
        setState({ error });
      });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <span className="input-text-top">Name</span>
      <br />
      <Input
        placeholder="First Name"
        className="cultivars-input-40"
        name="firstName"
        value={firstName}
        onChange={(e) =>
          setState({
            ...state,
            firstName: e.target.value,
          })
        }
      />
      <Input
        placeholder="Last Name"
        className="cultivars-input-40"
        name="lastName"
        value={lastName}
        onChange={(e) =>
          setState({
            ...state,
            lastName: e.target.value,
          })
        }
      />
      <br />
      <span className="input-text-top">Email</span>
      <br />
      <Input
        placeholder="Email"
        className="cultivars-input-40"
        name="email"
        value={email}
        onChange={(e) =>
          setState({
            ...state,
            email: e.target.value,
          })
        }
        type="email"
      />
      <br />
      <span className="input-text-top">Address</span>
      <br />
      <Input
        placeholder="Address Line 1"
        className="cultivars-input-40"
        name="address_one"
        value={address_one}
        onChange={(e) =>
          setState({
            ...state,
            address_one: e.target.value,
          })
        }
      />
      <Input
        placeholder="Address Line 2"
        className="cultivars-input-40"
        name="address_two"
        value={address_two}
        onChange={(e) =>
          setState({
            ...state,
            address_two: e.target.value,
          })
        }
      />
      <br />
      <Input
        placeholder="City"
        className="cultivars-input-40"
        name="city"
        value={city}
        onChange={(e) =>
          setState({
            ...state,
            city: e.target.value,
          })
        }
      />
      <Input
        placeholder="Zip"
        className="cultivars-input-40"
        name="zip"
        value={zip}
        onChange={(e) =>
          setState({
            ...state,
            zip: e.target.value,
          })
        }
      />
      <br />
      <Query query={QUERY_ALL_COUNTRIES}>
        {({ loading, error, data }) => {
          if (loading) return <div>Loading</div>;
          if (error) return <LoadError />;

          const countries = data.countries;

          return (
            <div>
              <Select
                className="cultivars-dropdown-final"
                placeholder="Country"
                suffixIcon={<CaretDownOutlined />}
                name="country"
                onSelect={(e) =>
                  setState({
                    ...state,
                    country: e,
                  })
                }
              >
                {countries.map((link) => (
                  <Option value={link.id}>{link.countryName}</Option>
                ))}
              </Select>
            </div>
          );
        }}
      </Query>
      <br />
      <span className="input-text-top">Telephone Number: </span>
      <br />
      <Input placeholder="Telephone Number" className="cultivars-input-40" />
      <br />
      <span className="input-text-top">Password: </span>
      <br />
      <Input
        placeholder="Created Password"
        type="password"
        className="cultivars-input-40"
        name="passwordOne"
        value={passwordOne}
        onChange={(e) =>
          setState({
            ...state,
            passwordOne: e.target.value,
          })
        }
      />
      <Input
        placeholder="Confirm Password"
        type="password"
        className="cultivars-input-40"
        name="passwordTwo"
        value={passwordTwo}
        onChange={(e) =>
          setState({
            ...state,
            passwordTwo: e.target.value,
          })
        }
      />
      <div className="submission-buttons">
        <Link to="/login">
          <Button type="primary" className="hortivar-gray-btn">
            Cancel <LeftOutlined />
          </Button>
        </Link>
        <Button
          type="primary"
          disabled={isInvalid}
          htmlType="submit"
          className="hortivar-orange-btn"
        >
          Register <LoginOutlined />
        </Button>
      </div>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const Register = () => {
  document.title = "Hortivar - Registration";

  return (
    <>
      <Row>
        <Col span={2}></Col>
        <Col span={20}>
          <div className="registration-panel">
            <div className="registration-panel-top">
              <Logo />
            </div>
            <div className="registration-panel-bottom">
              <Row>
                <Col span={6}>
                  <div className="logo">
                    <HortivarLogo />
                  </div>
                  <br />
                  <h3>Registration</h3>
                </Col>
                <Col span={18}>
                  <RegisterForm />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={2}></Col>
      </Row>
    </>
  );
};

const RegisterForm = withRouter(withFirebase(RegisterBase));

export default Register;
