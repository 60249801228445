import { Button } from 'antd';
import React from 'react';
import { withFirebase } from '../components/auth';

const SignOutButton = ({ firebase }) => (
    <Button type="link" onClick={firebase.doSignOut}>
      Logout
    </Button>
);
 
export default withFirebase(SignOutButton);