import { Button, Col, Row } from 'antd';

import React from 'react'
import { Link } from 'react-router-dom';
import ReactQuill from "react-quill";
import {SaveOutlined} from "@ant-design/icons";

const NotLoggedInComments = () => {
    return (
        <>
            <Row>
                <Col span={10} className="gmh-post-left">

                </Col>
                <Col span={14}>
                    <div>
                        <p className="p-not-found-text">You are not logged in, please log in or register to comment on this post. <Link to="/login">Login</Link> or  <Link to="/register">Register</Link>
                        </p>
                        <br/>
                        <ReactQuill
                            theme="snow"
                            readOnly
                            className="post-comment-textfield"
                            placeholder={"Login or register to comment.."}
                        />
                        <Button
                            className="gmh-submit-comment-btn"
                            disabled={true}
                        >Submit answer <SaveOutlined/>
                        </Button>

                    </div>
                </Col>
            </Row>
        </>
    )
};

export default NotLoggedInComments;
