import { Col, Row } from 'antd';

import React from 'react'
import { Link } from 'react-router-dom';

const NoRecords = () => {
    document.title = 'Hortivar - Performance Cultivars Database'
    return (
        <>
        <Row className="content">
            <Col className="sidebar" span={5}></Col>
            <Col span={14} className="error">
                <h1 className="not-found-error">404</h1>
                <p className="p-not-found-text">There are no posts available, go ahead and create one <Link to="/create-post">here</Link></p>
            </Col>
            <Col className="sidebar" span={5}></Col>
        </Row>
        </>
    )
};

export default NoRecords;