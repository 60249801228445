import { Collapse } from 'antd';

const { Panel } = Collapse;

const NutritionalInformation = (props) => {
  console.log(props.nutrition)

  function camelize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


    const { nutrition } = props;
    if (!nutrition || nutrition.length === 0) return <p>No nutritional infomation, sorry...</p>;
    return (
        <>
            <Collapse defaultActiveKey={[nutrition.foods[0].fdcId]} className="cultivar-information-tabs">
            {nutrition.foods.map((item) => {
            return (
                <Panel header={camelize(item.lowercaseDescription)} key={item.fdcId} className="nutritional-information">
                    {item.foodNutrients.map((x) => {
                      return <>
                          <p>
                            {x.nutrientName}: <span>{x.value} {x.unitName.toLowerCase()}</span>
                          </p>
                      </>
                    })}
                </Panel>
            );
            })}
            </Collapse>
        </>
    );
  };

export default NutritionalInformation;
  