import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import CreateGoodMorningPost from "./pages/CreateGoodMorningPost";
import CultivarsPage from "./pages/CultivarsPage";
import HortivarFooter from "./HortivarFooter";
import KnowledgeBasePage from "./pages/KnowledgeBase";
import { Layout } from "antd";
import ListCultivars from "./pages/ListCultivars";
import ViewProfile from "./pages/Profile";
import ListGoodMorningPosts from "./pages/ListGoodMorningPosts";
import Login from "./pages/Login";
import Navbar from "./Navbar";
import Network from "./pages/Network";
import NotFoundPage from "./pages/NotFoundPage";
import React from "react";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import Reports from "./pages/Reports";
import SearchRecords from "./pages/SearchRecords";
import Statistics from "./pages/Statistics";
import SubNav from "./SubNav";
import ViewCultivar from "./pages/ViewCultivar";
import ViewPost from "./pages/ViewPost";
import { withFirebase } from "./components/auth";
import AdvancedSearch from "./pages/AdvancedSearch";

const { Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <>
        <Router>
          <div className="App">
            <Navbar authUser={this.state.authUser} />
            <SubNav />
            <div id="page-body">
              <Layout className="layout">
                <Content style={{ padding: "0 90px" }}>
                  <div className="site-layout-content">
                    <Switch>
                      <Route path="/" component={ListCultivars} exact />
                      <Route
                        path="/create-record"
                        component={() => (
                          <CultivarsPage authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        path="/performance-cultivars"
                        component={ListCultivars}
                      />
                      <Route path="/statistics" component={Statistics} />
                      <Route path="/reports" component={Reports} />
                      <Route path="/search/:query" component={SearchRecords} />
                      <Route
                        path="/knowlegde-base"
                        component={KnowledgeBasePage}
                      />
                      <Route path="/network" component={Network} />
                      <Route path="/contact" component={Contact} />
                      <Route
                        path="/advanced-search"
                        component={AdvancedSearch}
                      />
                      <Route path="/login" component={Login} />
                      <Route
                        exact
                        path="/view-cultivar/:cultivar_id"
                        component={ViewCultivar}
                      />
                      <Route
                        path="/view-post/:post_id"
                        render={(props) => (
                          <ViewPost authUser={this.state.authUser} {...props} />
                        )}
                      />
                      <Route path="/register" component={Register} />
                      <Route path="/gmh" component={ListGoodMorningPosts} />
                      <Route
                        path="/profile"
                        component={() => (
                          <ViewProfile authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        path="/search"
                        component={() => (
                          <SearchRecords authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        path="/create-post"
                        component={() => (
                          <CreateGoodMorningPost
                            authUser={this.state.authUser}
                          />
                        )}
                      />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </div>
                </Content>
              </Layout>
            </div>
            <HortivarFooter />
          </div>
        </Router>
      </>
    );
  }
}

export default withFirebase(App);
