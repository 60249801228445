import { Col, Row } from 'antd'

import React from 'react'
import { Link } from 'react-router-dom'

const NotLoggedIn = () => {
   document.title = 'HORTIVAR - Cultivars Performance Database'
   return (
      <>
         <Row className="content">
            <Col className="sidebar" span={5}></Col>
            <Col span={14} className="error-pane">
               <h1 className="not-found-error">401</h1>
               <p className="p-not-found-text">
                  You are not logged in, please log in or register access this
                  page.
               </p>
               <p className="p-not-found-text">
                  <Link to="/login">Login</Link> or{' '}
                  <Link to="/register">Register</Link>
               </p>
            </Col>
            <Col className="sidebar" span={5}></Col>
         </Row>
      </>
   )
}

export default NotLoggedIn
