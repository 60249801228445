import { Button, Col, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom'

import { ReactComponent as Pin } from './assets/logo.svg';
import React from 'react'
import SearchBar from './components/Search';

const SubNav = () => {
    // if (window.location.pathname === '/login/' || window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/register/') return null;
    return (
    <>
        <Row className="subnav">
            <Col className="sidebar" span={3}>
                <NavLink to="/">
                    <Pin className="logo"/>
                </NavLink>
            </Col>
            <Col span={3} className="subnav-links">
                <div className="nav-sub">
                    <NavLink to="/performance-cultivars/">All Records</NavLink>
                </div>
            </Col>
            <Col span={3} className="submit-entry">
            <NavLink to="/create-record/">
            <Button type="default" shape="round" size="large" className="submit-entry-btn">
                    Submit Entry <PlusCircleOutlined />
                </Button>
            </NavLink>
            </Col>
            <Col span={10} className="search-entry">
                <SearchBar/>
            </Col>
            <Col span={5} className="subnav-links">
                <div className="nav-sub">
                    <NavLink to="/statistics/">Statistics</NavLink>
                    <NavLink to="/gmh/">GMH</NavLink>
                </div>
            </Col>
        </Row>
    </>);
}

const HideSubNav = withRouter(SubNav);
export default HideSubNav;
