import { Alert, Button, notification, Col, Modal, Row } from 'antd'
import React, { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import dompurify from 'dompurify'

import { ReactComponent as Down } from '../assets/down.svg'
import { ReactComponent as DownVote } from '../assets/thumbdown.svg'
import { FormOutlined, SaveOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import GMHLoading from '../components/GMHLoading'
import LoadError from '../components/LoadError'
import { ReactComponent as Location } from '../assets/country.svg'
import { ReactComponent as LocationFilled } from '../assets/countryfilled.svg'
import { ReactComponent as Up } from '../assets/up.svg'
import { ReactComponent as UpVote } from '../assets/thumbup.svg'
import { ReactComponent as UserFilled } from '../assets/userfilled.svg'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import Gravatar from 'react-gravatar'
import NotLoggedInComments from '../components/NotLoggedInComments'
import ReactQuill from 'react-quill'

const GET_RECORD = gql`
   query posts($id: ID) {
      posts(id: $id) {
         id
         title
         text
         views
         points
         createdAt
         user {
            id
            firstName
            lastName
            email
            country {
               id: m49Code
               countryName
            }
         }
         comments {
            id
            text
            downvote
            upvote
            createdAt
            user {
               id
               firstName
               email
               lastName
               country {
                  id: m49Code
                  countryName
               }
            }
         }
      }
   }
`

const CREATE_GM_COMMENT = gql`
   mutation createComment($input: CreateCommentInput!) {
      createComment(input: $input) {
         clientMutationId
         comment {
            id
         }
         errors
      }
   }
`

const UPDATE_VIEWS = gql`
   mutation updatePostViews($input: UpdatePostViewsInput!) {
      updatePostViews(input: $input) {
         post {
            id
            views
         }
      }
   }
`

const ViewPost = (props) => {
   const sanitizer = dompurify.sanitize
   const id = props.match.params.post_id

   const initialState = {
      comment: '',
      user: '',
   }

   const [state, setState] = useState(initialState)
   const { comment } = state
   const { loading, error, data, refetch: _refetch } = useQuery(GET_RECORD, {
      variables: { id: id },
   })
   const refetch = useCallback(() => {
      setTimeout(() => _refetch(), 0)
   }, [_refetch])
   const [createComment] = useMutation(CREATE_GM_COMMENT, {
      variables: {
         input: {
            attributes: { userId: state.user, postId: id, text: state.comment },
         },
      },
      refetchQueries: [`posts`],
   })

   const [createPostViews] = useMutation(UPDATE_VIEWS, {
      variables: {
         input: { id: parseInt(id) },
      },
   })

   function submitAndUpdate() {
      createComment()
      refetch()
      setState({ country: '' })
   }

   const [confirmVisited, setVisited] = React.useState(false)

   if (id != null && confirmVisited === false) {
      createPostViews()
      setVisited(true)
      refetch()
   }

   const isInvalid = comment === ''

   // Comment Modal
   const [visible, setVisible] = React.useState(false)
   const [confirmLoading, setConfirmLoading] = React.useState(false)

   const showModal = () => {
      setVisible(true)
   }

   const openNotificationWithIcon = (type) => {
      notification[type]({
         message: 'Validation error.',
         description:
            'Your answer cannot be blank: You must provide an answer to this post to be able to submit.',
      })
   }

   const handleOk = () => {
      if (isInvalid) {
         openNotificationWithIcon('error')
      } else {
         submitAndUpdate()
         setConfirmLoading(true)
         setTimeout(() => {
            setVisible(false)
            setConfirmLoading(false)
         }, 100)
      }
   }

   const handleCancel = () => {
      setVisible(false)
   }

   if (loading) return <GMHLoading />
   if (error) return <LoadError />

   return (
      <>
         <Row>
            <Col className="gmh-header" span={12}>
               <p>Good Morning HORTIVAR</p>
               <p className="gmh-h2">Question</p>
               <br />
            </Col>
            <Col span={12}>
               <Link to="/create-post">
                  <Button className="gmh-submit-post-btn">
                     Post a question <FormOutlined />
                  </Button>
               </Link>
            </Col>
         </Row>
         <Row>
            <Col span={10} className="gmh-post-left">
               <Row>
                  <Col span={12}>
                     <p className="title-two">Posted by</p>
                     <p className="title-three">
                        {moment(data.posts[0].createdAt).format(
                           'MMMM Do YYYY, h:mm:ss a'
                        )}
                     </p>
                     <p>
                        <span className="round-label-full">
                           {data.posts[0].views} Views
                        </span>
                     </p>
                     <span className="round-label-full">
                        {data.posts[0].comments.length} Answers
                     </span>
                  </Col>
                  <Col span={12}>
                     <div className="author-card">
                        <Row>
                           <Col span={6}>
                              <p>
                                 <Gravatar
                                    email={data.posts[0].user.email}
                                    size={25}
                                    className="gmh-profile-logo"
                                 />
                              </p>
                              <p>
                                 <Location className="author-logo" />
                              </p>
                           </Col>
                           <Col span={1}></Col>
                           <Col span={17}>
                              <p className="author-name">
                                 {data.posts[0].user.firstName ?? 'HORTIVAR'}
                              </p>
                              <p className="author-points">
                                 {data.posts[0].points} points
                              </p>
                              <p>
                                 <DownVote /> <UpVote />
                              </p>
                              <p className="author-bottom">
                                 {data.posts[0].user.country.countryName}
                              </p>
                           </Col>
                        </Row>
                     </div>
                  </Col>
               </Row>
            </Col>
            <Col span={14}>
               <div className="question-card">
                  <p className="question-title">{data.posts[0].title}</p>
                  <p
                     className="question-text"
                     dangerouslySetInnerHTML={{
                        __html: sanitizer(data.posts[0].text),
                     }}
                  />
                  {props.authUser ? (
                     <>
                        <Modal
                           title="Submit answer"
                           centered
                           visible={visible}
                           onOk={handleOk}
                           width={620}
                           confirmLoading={confirmLoading}
                           className="submit-answer-modal"
                           onCancel={handleCancel}
                           okText="Submit Answer"
                           cancelText="Cancel"
                        >
                           <ReactQuill
                              theme="snow"
                              className="submit-answer-textfield-modal"
                              onChange={(e) =>
                                 setState({
                                    ...state,
                                    comment: e,
                                    user: props.authUser.uid,
                                 })
                              }
                              value={state.comment}
                           />
                        </Modal>

                        <Button
                           type="default"
                           shape="round"
                           size="large"
                           className="submit-comment-btn"
                           onClick={showModal}
                        >
                           Submit an answer <FormOutlined />
                        </Button>
                     </>
                  ) : (
                     <>
                        <Button
                           type="ghost"
                           shape="round"
                           size="large"
                           className="submit-comment-btn"
                           disabled
                        >
                           Submit an answer <FormOutlined />
                        </Button>
                     </>
                  )}
               </div>
            </Col>
         </Row>
         <Row>
            <Col className="gmh-header" span={24}>
               <p className="gmh-h2">Answers</p>
            </Col>
         </Row>
         {data.posts[0].comments.length === 0 ? (
            <>
               <Row className="comments-alert">
                  <Col span={10}></Col>
                  <Col span={14}>
                     <Alert
                        message="There are currently no answers on this post. Be the first and comment down below.."
                        type="info"
                        showIcon
                     />
                     <br />
                  </Col>
               </Row>
            </>
         ) : (
            data.posts[0].comments.map((comment, i) => (
               <Row className="comments">
                  <Col span={10} className="gmh-post-left">
                     <Row>
                        <Col span={12}>
                           <p className="title-sub">Answered by</p>
                           <p className="title-three">
                              {moment(comment.createdAt).format('Do MMMM YYYY')}
                           </p>
                        </Col>
                        <Col span={12}>
                           <div className="author-card gray-card">
                              <Row>
                                 <Col span={5}>
                                    <p>
                                       <UserFilled />
                                    </p>
                                    <p>
                                       <LocationFilled className="author-logo" />
                                    </p>
                                 </Col>
                                 <Col span={19}>
                                    <p className="author-name">
                                       {comment.user.firstName}
                                    </p>
                                    <p className="author-points">
                                       {comment.upvote} points
                                    </p>
                                    <p>
                                       <DownVote /> <UpVote />
                                    </p>
                                    <p className="author-bottom">
                                       {comment.user.country.countryName}
                                    </p>
                                 </Col>
                              </Row>
                           </div>
                        </Col>
                     </Row>
                  </Col>
                  <Col span={14}>
                     <Row>
                        <Col span={4}>
                           <p className="answer-header">Answer {i + 1}</p>
                           <Row>
                              <Col span={4}>
                                 <Up />
                              </Col>
                              <Col span={20} className="votes">
                                 Up vote Answer
                              </Col>
                              <Col span={4}>
                                 <Down />
                              </Col>
                              <Col span={20} className="votes">
                                 Down vote Answer
                              </Col>
                           </Row>
                        </Col>
                        <Col span={20}>
                           <p
                              className="question-text"
                              dangerouslySetInnerHTML={{
                                 __html: sanitizer(comment.text),
                              }}
                           />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            ))
         )}

         {props.authUser ? (
            <>
               <Row className="comment-section">
                  <Col span={10}></Col>
                  <Col span={14}>
                     <div>
                        <ReactQuill
                           theme="snow"
                           className="post-comment-textfield"
                           placeholder={'Your answer'}
                           onChange={(e) =>
                              setState({
                                 ...state,
                                 comment: e,
                                 user: props.authUser.uid,
                              })
                           }
                           value={state.comment}
                        />
                     </div>

                     <Button
                        className="gmh-submit-comment-btn"
                        disabled={isInvalid}
                        onClick={() => {
                           submitAndUpdate()
                        }}
                     >
                        Submit answer <SaveOutlined />
                     </Button>
                  </Col>
               </Row>
            </>
         ) : (
            <NotLoggedInComments />
         )}
      </>
   )
}

export default ViewPost
