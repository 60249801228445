import { Button, Col, Input, Row, message } from 'antd';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import NotLoggedIn from '../pages/NotLoggedIn';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useHistory } from 'react-router';

const CREATE_GM_MUTATION = gql`
  mutation createGoodMorningPost(
      $title: String!, 
      $text: String!, 
      $user: String!
      ) {
        createGoodMorningPost(user: $user, title: $title, text: $text) {
            goodMorningPost {
                id
            }
        }
 }
`;


const CreateGoodMorningPost = (props) => {
    const history = useHistory();

    const [state, setState] = useState({
        text: '',
        title: '',
        user: '',
        value: ''
      });

    const {text, title} = state;

    const [createPost] = useMutation(CREATE_GM_MUTATION, {
        variables: {
            text: state.text,
            title: state.title,
            user: state.user,
        },
        onCompleted: (data) => {
            history.push('/gmh')
        }
    });

    const isInvalid = text === "" && title === "";
    console.log(state)
    
    return (
        <>
            {
                props.authUser ? 
                <>  
                    <Col className="gmh-header" span={12}>
                        <p>Good Morning HORTIVAR</p>
                        <p>Question</p>
                    </Col>
                    <Row>
                        <Col span={10} className="gmh-post-left">
                            <Row>
                                <Col span={12}>
                                    <p>Edit mode</p>
                                </Col>
                                <Col span={12}>
                                    <div className="author-card">
                                        <p className="author-name">{props.authUser.displayName}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={14}>
                            <div id="post-question">
                                <Input 
                                placeholder="Title" 
                                className="post-question-title" 
                                name="title"
                                onChange={
                                    (e) =>
                                    setState({
                                        ...state,
                                        title: e.target.value
                                    })
                                }
                                onSelect={
                                    () => setState({...state, user: props.authUser.uid})
                                }
                                />

                                <ReactQuill theme="snow"
                                className="post-question-textfield"
                                onChange={
                                    (e) => setState({...state, text: e })
                                }
                                />

                                <Row justify="space-between" className="post-question-buttons">
                                    <Col span={7}>
                                        <Button className="gmh-submit-post-btn first">Cancel
                                        </Button>
                                    </Col>
                                    <Col span={7}>
                                        <Button className="gmh-submit-post-btn second">Upload Media
                                        </Button>
                                    </Col>
                                    <Col span={7} >
                                        <Button 
                                        className="gmh-submit-post-btn third"
                                        disabled={isInvalid}
                                        onClick={() => {
                                            createPost();
                                            message.success('Processing complete!')
                                        }}
                                        >Post a question
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </>
                : <NotLoggedIn/>
            }
        </>
    );
}

export default CreateGoodMorningPost;