import { Col, Layout, Row } from 'antd';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';

import { ReactComponent as FooterLogo } from './assets/hortivar_footer.svg';
import { NavLink } from 'react-router-dom';
import React from 'react'

const { Footer } = Layout;

const HortivarFooter = () => {
    // if (window.location.pathname === '/login/' || window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/register/') return null;
    return (
        <>  <div className="main-footer">
            <div id="footer-flowers">
            </div>
            <Footer className="footer">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row footer-version" span={7}>
                        <p>
                            © Hortivar version 1.0,  Last updated:  Feb 2021
                            NSPLD, FAO - MTF/GLO/697/MUL<br/>© FAO, 2020
                    </p>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div>
                            <FooterLogo />
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div className="footer-links">
                            <NavLink to="/contact/">About HORTIVAR</NavLink><br />
                            <NavLink to="/gmh/">GMH</NavLink><br />
                            <NavLink to="/">All Records</NavLink><br />
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div className="footer-links">
                            {/* <NavLink to="/search-records/">About Hortivar</NavLink><br />
                            <NavLink to="/create-record/">Contact Admin</NavLink><br /> */}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={7}>
                        <div className="footer-social">
                            <p>Follow us on: <span><FacebookOutlined /></span>  <span><TwitterOutlined /></span>  <span><YoutubeOutlined /></span>  <span><InstagramOutlined /></span> <span><LinkedinOutlined /></span></p>
                        </div>
                    </Col>
                </Row>
            </Footer>
            </div>
        </>
    );
}

export default HortivarFooter;