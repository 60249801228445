import 'antd/dist/antd.css';

import {GlobalOutlined, LoginOutlined, LogoutOutlined} from '@ant-design/icons';
import {Layout, Menu} from 'antd';

import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';
import { NavLink } from 'react-router-dom';
import React from 'react';
import SignOutButton from './components/SignOut';

const { Header} = Layout;

const NavigationAuth = (props) => {
    // if (window.location.pathname === '/login/' || window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/register/') return null;
    return (
        <Header>
            <div className="logo">
                <NavLink to="/">
                    <Logo/>
                </NavLink>
            </div>
            <Menu  className="menu-nav" mode="horizontal">
                <Menu.Item {...props} className="right-nav-header" key="1" style={{float: 'right'}}>
                    <Link to="/login/">
                        <SignOutButton/><LogoutOutlined />
                    </Link>
                </Menu.Item>
                <Menu.Item {...props} className="left-nav-header" key="2" style={{float: 'right'}}>
                    <Link to="/profile">
                        Profile
                    </Link>
                </Menu.Item>
                <Menu.Item {...props} className="left-nav-header" key="3" style={{float: 'right'}}>
                    Language  <GlobalOutlined />
                </Menu.Item>
            </Menu>
        </Header>
    )
};
  
const NavigationNonAuth = (props) => {
    // if (window.location.pathname === '/login/' || window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/register/') return null;
    return (
        <Header>
            <div className="logo">
                <NavLink to="/">
                    <Logo/>
                </NavLink>
            </div>
            <Menu  className="menu-nav" mode="horizontal">
                <Menu.Item {...props} className="right-nav-header" key="1" style={{float: 'right'}}>
                    <NavLink to="/login/">
                        Login <LoginOutlined />
                    </NavLink>
                </Menu.Item>
                <Menu.Item {...props} className="left-nav-header" key="2" style={{float: 'right'}}>
                    Language  <GlobalOutlined />
                </Menu.Item>
            </Menu>
        </Header>
    )
};

const Navbar = ({ authUser }) => (
    <div>
        {
            authUser ? 
            <NavigationAuth /> 
            : <NavigationNonAuth />
        }
    </div>
);

export default Navbar;