import { Card, DatePicker, Input, Progress, Select, Tooltip } from 'antd'
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons'

import React from 'react'

const { Option } = Select

class Production extends React.Component {
   render() {
      return (
         <>
            <Card className="cultivars-container">
               <span className="header-text">
                  Production{' '}
                  <span>
                     Completion Index:{' '}
                     <Progress
                        type="circle"
                        percent={50}
                        strokeColor="#356B0C"
                     />
                  </span>
               </span>
               <br />
               <Select
                  className="cultivars-dropdown-final"
                  placeholder="Production system"
                  onSelect={(value) =>
                     this.props.handleSelectChange(value, 'productionSystem')
                  }
                  value={this.props.productionSystem || 'Production system'}
                  suffixIcon={<CaretDownOutlined />}
               >
                  <Option value="0">Low tunnel</Option>
                  <Option value="1">Walk-in tunnel</Option>
                  <Option value="2">Greenhouse</Option>
                  <Option value="3">Shelter structure</Option>
                  <Option value="4">Net-house</Option>
                  <Option value="5">Micro-garden</Option>
                  <Option value="6">Open field</Option>
                  <Option value="7">Laboratory</Option>
                  <Option value="8">Organoponics</Option>
                  <Option value="9">Other (specify in notes)</Option>
               </Select>
               <Select
                  className="cultivars-dropdown-final"
                  placeholder="Production method"
                  onSelect={(value) =>
                     this.props.handleSelectChange(value, 'productionMethod')
                  }
                  value={this.props.productionMethod || 'Production method'}
                  suffixIcon={<CaretDownOutlined />}
               >
                  <Option value="0">Conventional</Option>
                  <Option value="1">Eco-label</Option>
                  <Option value="2">Organic</Option>
               </Select>
               <br />
               <Select
                  className="cultivars-dropdown-final"
                  placeholder="Culture media"
                  onSelect={(value) =>
                     this.props.handleSelectChange(value, 'cultureMedia')
                  }
                  value={this.props.cultureMedia || 'Culture media'}
                  suffixIcon={<CaretDownOutlined />}
               >
                  <Option value="0">Soil</Option>
                  <Option value="1">Substrate</Option>
                  <Option value="2">Water solution (hydroponic)</Option>
               </Select>
               <br />
               <DatePicker
                  className="cultivars-dropdown"
                  placeholder="Plant Date"
                  onChange={(value) =>
                     this.props.handleSelectChange(value, 'plantDate')
                  }
                  value={this.props.plantDate}
               />
               <br />
               <Select
                  className="cultivars-dropdown-final"
                  placeholder="Target plant product"
                  onSelect={(value) =>
                     this.props.handleSelectChange(value, 'targetProduct')
                  }
                  value={this.props.targetProduct || 'Target product'}
                  suffixIcon={<CaretDownOutlined />}
               >
                  <Option value="0">Leaves</Option>
                  <Option value="1">Fruits</Option>
                  <Option value="2">Roots & Tubers</Option>
                  <Option value="3">Seeds</Option>
                  <Option value="4">Inflorescence</Option>
                  <Option value="5">Stem</Option>
                  <Option value="6">Seedlings</Option>
                  <Option value="7">Pot Plants</Option>
                  <Option value="8">Cut Flowers</Option>
                  <Option value="9">Sprouts</Option>
                  <Option value="10">Bulb</Option>
                  <Option value="11">Fruiting Body</Option>
                  <Option value="12">Other (specify in notes)</Option>
               </Select>
               <br />
               <br />
               <Input
                  placeholder="Planting density (plants/ha)"
                  className="cultivars-input-50"
                  suffix={
                     <Tooltip title="Same unit as fresh yield">
                        <InfoCircleOutlined
                           style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                     </Tooltip>
                  }
                  name="density"
                  onChange={this.props.handleChange}
                  value={this.props.density}
               />
            </Card>
         </>
      )
   }
   r
}

export default Production
