import { Col, Divider, Row, Tabs, Image } from "antd";
import NutritionalInformation from "../NutritionalInformation";
import withListLoading from "../withLoadingAPI";

import React, { useEffect, useState } from "react";
import axios from "axios";

const { TabPane } = Tabs;

const CultivarInformationTabs = ({ data }) => {
  const ListLoading = withListLoading(NutritionalInformation);

  const [appState, setAppState] = useState({
    loading: false,
    nutrition: null,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `https://api.nal.usda.gov/fdc/v1/foods/search?api_key=wa3TVAFMMQk7dxTXqXxSh6BaN0HakJD0e70T1VWi&query=${data.performanceCultivar[0].species.commonName}&pageSize=5`;
    axios.get(apiUrl).then((repos) => {
      const nutrition = repos.data;
      setAppState({ loading: false, nutrition: nutrition });
    });
  }, [setAppState, data]);

  return (
    <>
      <br />
      <Tabs tabPosition="left" className="cultivar-information-tabs">
        <TabPane tab="General Information" key="1">
          <div>
            <Row>
              <Col span={12}>
                <p>Crop category</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].cropType}</span>
              </Col>
              <br />
              <br />
              <br />
              <Col span={12}>Location</Col>
              <Col span={12}>
                <span></span>
              </Col>
              <Divider dashed />
              <Col span={12}>
                <p>Country</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].country.countryName}</span>
              </Col>
              <Col span={12}>
                <p>Locality</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].country.countryName}</span>
              </Col>
              <br />
              <br />
              <br />
              <Col span={12}>Species</Col>
              <Col span={12}>
                <span></span>
              </Col>
              <Divider dashed />
              <Col span={12}>
                <p>Common name</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].species.commonName}</span>
              </Col>
              <Col span={12}>
                <p>Latin name</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].species.latinName}</span>
              </Col>
              <Col span={12}>
                <p>Cultivar type</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].cultivarType}</span>
              </Col>
              <Col span={12}>
                <p>Cultivar name</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].commonName}</span>
              </Col>
              <Col span={12}>
                <p>Grafted</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].grafted}</span>
              </Col>
              <br />
              <br />
              <br />
              <Col span={12}>If grafted then?</Col>
              <Col span={12}>
                <span></span>
              </Col>
              <Divider dashed />
              <Col span={12}>
                <p>Rootstock species name</p>
              </Col>
              <Col span={12}>
                <span>-</span>
              </Col>
              <Col span={12}>
                <p>Rootstock Latin species name</p>
              </Col>
              <Col span={12}>
                <span>-</span>
              </Col>
              <Col span={12}>
                <p>Rootstock cultivar type</p>
              </Col>
              <Col span={12}>
                <span>-</span>
              </Col>
              <Col span={12}>
                <p>Rootstock cultivar name</p>
              </Col>
              <Col span={12}>
                <span>-</span>
              </Col>
              <br />
            </Row>
          </div>
        </TabPane>
        <TabPane tab="Basic Crop & Yield" key="2">
          <div>
            <Row>
              <Col span={12}>
                <p>Target plant product</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].targetProduct}</span>
              </Col>
              <Col span={12}>
                <p>Production system</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].productionSystem}</span>
              </Col>
              <Col span={12}>
                <p>Culture Media</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].cultureMedia}</span>
              </Col>
              <Col span={12}>
                <p>Data record environment</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].recordEnvironment}</span>
              </Col>
              <Col span={12}>
                <p>Planting/seeding system</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].seedSystem}</span>
              </Col>
              <Divider dashed />
              <Col span={12}>Production Method</Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].productionMethod}</span>
              </Col>
              <Divider dashed />
              <Col span={12}>
                <p>Planting density (plants/ha)</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].density}</span>
              </Col>
              <Col span={12}>
                <p>Total fresh yield</p>
              </Col>
              <Col span={12}>
                <span>
                  {data.performanceCultivar[0].unitYield} /{" "}
                  {data.performanceCultivar[0].unit}
                </span>
              </Col>
              <Col span={12}>
                <p>Planting date (dd/mm/yyyy)</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].plantDate}</span>
              </Col>
              <Col span={12}>
                <p>First harvest date</p>
              </Col>
              <Col span={12}>
                <span>
                  {data.performanceCultivar[0].startHarvestDate},{" "}
                  {data.performanceCultivar[0].startAmount}{" "}
                  {data.performanceCultivar[0].unit}
                </span>
              </Col>
              <Col span={12}>
                <p>Peak harvest date</p>
              </Col>
              <Col span={12}>
                <span>
                  {data.performanceCultivar[0].peakHarvestDate},{" "}
                  {data.performanceCultivar[0].peakAmount}{" "}
                  {data.performanceCultivar[0].unit}
                </span>
              </Col>
              <Col span={12}>Last harvest date</Col>
              <Col span={12}>
                <span>
                  {data.performanceCultivar[0].lastHarvestDate},{" "}
                  {data.performanceCultivar[0].lastAmount}{" "}
                  {data.performanceCultivar[0].unit}
                </span>
              </Col>
              <Divider dashed />
              <Col span={12}>
                <p>Crop cycle (field occupation)</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].circleValue} days</span>
              </Col>
              <Col span={12}>
                <p>Notes</p>
              </Col>
              <Col span={12}>
                <span>{data.performanceCultivar[0].notes}</span>
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane tab="Cultivar" key="3">
          <Row>
            <Col span={12}>Standard Cultivar Description</Col>
            <Col span={12}>
              <span></span>
            </Col>
            <Divider dashed />

            <p>
              Actual cultivar characteristics as observed under local field
              conditions
            </p>
            <Col span={12}>Tolerance / resistance</Col>
            <Col span={12}>
              <span></span>
            </Col>
            <Divider dashed />
            <Col span={12}>
              <p>Abiotic disorder</p>
            </Col>
            <Col span={12}>
              <span>
                <p>
                  1.Virus - CMV - Cucumber Mosaic and the longer string that was
                  truncated originally
                </p>
              </span>
            </Col>
            <br />
            <Col span={12}>
              <p>Pest</p>
            </Col>
            <Col span={12}>
              <span>1. Nematode - Meloidogyne spp. </span>
            </Col>
            <br />
            <Col span={12}>Susceptibility</Col>
            <Col span={12}>
              <span></span>
            </Col>
            <Divider dashed />
            <Col span={12}>
              <p>Abiotic disorder</p>
            </Col>
            <Col span={12}>
              <span>
                <p>
                  1.Virus - CMV - Cucumber Mosaic and the longer string that was
                  truncated originally
                </p>
              </span>
            </Col>
            <br />
            <Col span={12}>
              <p>Pest</p>
            </Col>
            <Col span={12}>
              <span>1. Nematode - Meloidogyne spp. </span>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Source" key="4">
          <p>Loading content....</p>
        </TabPane>
        <TabPane tab="USDA Nutrition database" key="5">
          <p className="usda-image">
            <Image src="https://fdc.nal.usda.gov/img/usda-symbol.svg" />
            <br></br>
            <br></br>Species: {data.performanceCultivar[0].species.commonName} ({" "}
            {data.performanceCultivar[0].species.latinName})
          </p>
          <ListLoading
            isLoading={appState.loading}
            nutrition={appState.nutrition}
          />
        </TabPane>
        <TabPane tab="Additional Information" key="6">
          <p>Loading content....</p>
        </TabPane>
      </Tabs>
    </>
  );
};

export default CultivarInformationTabs;
