import { Col, Row } from 'antd';

import CultivarInformationTabs from '../components/view_cultivar/CultivarInformationTabs';
import LeftPanel from '../components/view_cultivar/LeftPanel';
import LoadError from '../components/LoadError';
import React from 'react';
import { ResponsiveChoropleth } from '@nivo/geo'
import countries from "./world_countries.json";
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks';

const CULTIVAR_RECORD = gql`
  query performanceCultivar($id: ID!){
    performanceCultivar(id: $id) {
      id
      commonName
      targetProduct
      productionSystem
      productionMethod
      recordEnvironment
      density
      grafted
      cropType
      latitude
      longitude
      cultivarType
      unit
      peakAmount
      unitYield
      firstAmount
      lastAmount
      seedSystem
      cultureMedia
      plantDate
      startHarvestDate
      lastHarvestDate
      peakHarvestDate
      notes
      circleValue
      country {
        id: m49Code
        countryName
        isoAlpha2Code
        isoAlpha3Code
      }
      species {
        id
        commonName
        latinName
      }
      user {
        id
        email
        firstName
        lastName
      }
  }
}`

const ViewCultivar = ({ match }) => {
  const id = match.params.cultivar_id;

  let {loading, error, data} = useQuery(CULTIVAR_RECORD, {
    variables: { id:id },
  });

  if (loading) return <></>;
  if (error) return <LoadError/>

  return (
    <>
      <Row>
          <Col className="search-results-sidebar" span={10}>
            <LeftPanel data={data}/>
          </Col>
          <Col className="view-cultivar-right-results" span={14}>
            <div style={{ height: "400px" }} className="search-results-map map">
            <ResponsiveChoropleth
              data={[
                {
                  "id": data.performanceCultivar[0].country.isoAlpha3Code,
                  "value": 1
                }
              ]}
              features={countries.features}
              colors="nivo"
              domain={[ 0, 1000000 ]}
              unknownColor="#dfdddd"
              label="properties.name"
              valueFormat=".2s"
              projectionTranslation={[ 0.5, 0.6 ]}
              projectionScale={115}
              projectionRotation={[ 0, 0, 0 ]}
              enableGraticule={false}
              graticuleLineColor="#dddddd"
              borderWidth={0.3}
              borderColor="#152538"
            />
            </div>
            <CultivarInformationTabs data={data}/>
          </Col>
      </Row>
    </>
  );

}

export default ViewCultivar;
