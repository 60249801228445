import {
   Alert,
   Button,
   Col,
   Form,
   Row,
   Select,
   Spin,
   Table,
   DatePicker,
   Switch,
   Tag,
} from 'antd'

import LoadError from '../components/LoadError'
import { Query } from 'react-apollo'
import React from 'react'
import gql from 'graphql-tag'
import {
   CloseCircleOutlined,
   CaretDownOutlined,
   SearchOutlined,
   ClearOutlined,
} from '@ant-design/icons'
import { ResponsiveChoropleth } from '@nivo/geo'
import countries from './world_countries.json'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CircleFlag } from 'react-circle-flags'

const { RangePicker } = DatePicker

const columns = [
   {
      title: 'ID',
      dataIndex: ['key'],
      key: 'id',
      width: 80,
      render: (value) => {
         return <Link to={`/view-cultivar/${value}`}>{value}</Link>
      },
   },
   {
      title: 'Common Name',
      dataIndex: ['commonName'],
      key: 'commonName',
   },
   {
      title: 'Target Product',
      dataIndex: ['targetProduct'],
      key: 'targetProduct',
   },
   {
      title: 'Production Method',
      dataIndex: ['productionMethod'],
      key: 'productionMethod',
   },
   {
      title: 'Date Created',
      dataIndex: ['dateCreated'],
      key: 'plantDate',
      render: (value) => {
         return moment(value).format('MMMM Do YYYY')
      },
      sorter: true,
   },
   {
      title: 'Country',
      dataIndex: ['country', 'isoAlpha2Code'],
      key: 'isoAlpha2Code',
      render: (value) => {
         return <CircleFlag countryCode={value.toLowerCase()} height="20" />
      },
   },
]

const ALL_CULTIVARS_QUERY = gql`
   query performanceCultivars(
      $commonName: String
      $cropType: String
      $productionMethod: String
      $productionSystem: String
      $cultureMedia: String
      $targetProduct: String
      $cultivarType: String
      $country: SearchCountryInput
   ) {
      performanceCultivar(
         commonName: $commonName
         cropType: $cropType
         country: $country
         productionSystem: $productionSystem
         productionMethod: $productionMethod
         targetProduct: $targetProduct
         cultivarType: $cultivarType
         cultureMedia: $cultureMedia
      ) {
         id
         key: id
         commonName
         country {
            id: isoAlpha3Code
            isoAlpha2Code
         }
         plantDate
         targetProduct
         productionMethod
         dateCreated: createdAt
      }
   }
`

const QUERY_ALL_SPECIES = gql`
   {
      species {
         id
         commonName
         latinName
      }

      countries {
         id: m49Code
         countryName
      }

      performanceCultivarEnums {
         recordUnit
         cultureMedia
         depthClass
         cropType
         recordUnit
         targetProduct
      }
   }
`

const { Option } = Select

class AdvancedSearch extends React.Component {
   componentDidMount() {
      document.title = 'HORTIVAR - Advanced Search'
   }

   formRef = React.createRef()

   initialState = {
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      data: [],
      map: [
         {
            id: '',
            value: '',
         },
      ],
      current: 0,
      commonName: '',
      species: '',
      cultivarType: '',
      users: '',
      cropType: '',
      grafted: false,
      country: '',
      longitude: '',
      latitude: '',
      altitude: '',
      unit: '',
      unitYield: '',
      density: '',
      seedSystem: '',
      firstAmount: '',
      peakAmount: '',
      lastAmount: '',
      productionSystem: '',
      productionMethod: '',
      recordEnvironment: '',
      cultureMedia: '',
      targetProduct: '',
      countryName: '',
      cordinates: '',
      plantDate: '',
      startHarvestDate: '',
      peakHarvestDate: '',
      lastHarvestDate: '',
      circleValue: 1,
      modal1Visible: false,
      modal2Visible: false,
      validForm: false,
      variables: {},
      menuItems: {
         cultivarType: {
            0: 'OP Seed',
            1: 'Hybrid Seed',
            2: 'Clone',
            3: 'Graft',
            4: 'GMO',
         },
         cropType: {
            0: 'Vegetables',
            1: 'Roots & Tubers',
            2: 'Ornamental',
            3: 'Fruits',
            4: 'Herbs & Condiments',
            5: 'Mushroom',
         },
         productionSystem: {
            0: 'Low tunnel',
            1: 'Walk-in tunnel',
            2: 'Greenhouse',
            3: 'Shelter structure',
            4: 'Net-house',
            5: 'Micro-garden',
            6: 'Open field',
            7: 'Laboratory',
            8: 'Organoponics',
            9: 'Other',
         },
         productionMethod: {
            0: 'Conventional',
            1: 'Eco-label',
            2: 'Organic',
         },
         cultureMedia: {
            0: 'Soil',
            1: 'Substrate',
            2: 'Water solution (hydroponic)',
         },
         targetProduct: {
            0: 'Leaves',
            1: 'Fruits',
            2: 'Roots & Tubers',
            3: 'Seeds',
            4: 'Inflorescence',
            5: 'Stem',
            6: 'Seedlings',
            7: 'Pot Plants',
            8: 'Cut Flowers',
            9: 'Sprouts',
            10: 'Bulb',
            11: 'Fruiting Body',
            12: 'Other (specify in notes)',
         },
      },
   }

   state = this.initialState

   onReset = () => {
      this.setState(this.initialState)
      this.setState({ validForm: false })
      console.log(this.state)

      this.formRef.current.resetFields()
   }

   start = () => {
      this.setState({ loading: true })
      // ajax request after empty completing
      setTimeout(() => {
         this.setState({
            selectedRowKeys: [],
            loading: false,
         })
      }, 1000)
   }

   onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.setState({ selectedRowKeys })
   }

   onFinish = () => {
      this.setState({ validForm: false })
      this.setState({ validForm: true })
      if (this.state.species !== '') {
         this.setState({
            variables: {
               commonName: this.state.species,
               cropType: this.state.cropType,
               country: { m49Code: this.state.country },
               cultivarType: this.state.cultivarType,
               productionMethod: this.state.productionMethod,
               productionSystem: this.state.productionSystem,
               cultureMedia: this.state.cultureMedia,
               targetProduct: this.state.targetProduct,
            },
         })
      }
   }

   render() {
      const map = []

      const { selectedRowKeys } = this.state
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
      }

      const handleSelectChange = (event, label) =>
         this.setState({
            [label]: event,
         })

      function capitalizeFirstLetter(string) {
         return string.charAt(0).toUpperCase() + string.slice(1)
      }

      function getMenuItems(key, object, menuItems, countryName) {
         if (key in menuItems) {
            if (!isNaN(object)) {
               return menuItems[key][object]
            } else {
               return object
            }
         } else {
            if (key === 'commonName') {
               return object
            } else if (key === 'country') {
               if (object['m49Code'] !== '') {
                  return countryName
               }
            } else {
               return null
            }
         }
      }

      return (
         <>
            <Row>
               <Col className="search-results-sidebar" span={8}>
                  <p className="search-result-p">Advanced Search</p>
                  <div className="search-results-photos">
                     <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        // onFinishFailed={onFinishFailed}
                     >
                        <Query
                           fetchPolicy="cache-first"
                           query={QUERY_ALL_SPECIES}
                        >
                           {({ loading, error, data }) => {
                              if (loading)
                                 return (
                                    <>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Species (search to select)"
                                             optionFilterProp="children"
                                             name="species"
                                             disabled
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Country"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="country"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Cultivar Type"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="cultivar"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Crop type"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="croptype"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Production system"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="productionSystem"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Production method"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="productionMethod"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Culture media"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="cultureMedia"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Select
                                             showSearch
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             placeholder="Target plant product"
                                             optionFilterProp="children"
                                             suffixIcon={<CaretDownOutlined />}
                                             name="targetPlantProduct"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <RangePicker
                                             className="cultivars-dropdown-light-100 cultivars-input"
                                             name="plantDate"
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          Grafted Cultivar <Switch />
                                       </Form.Item>
                                    </>
                                 )
                              if (error) return <LoadError />

                              const species = data.species
                              const countries_remote = data.countries

                              return (
                                 <div>
                                    <Form.Item
                                       name="species"
                                       rules={[
                                          {
                                             required: true,
                                          },
                                       ]}
                                    >
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Species (search to select)"
                                          optionFilterProp="children"
                                          name="species"
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'species'
                                             )
                                          }
                                          value={
                                             this.species ||
                                             'Species (search to select)'
                                          }
                                       >
                                          {species.map((specie) => (
                                             <Option
                                                key={specie.id}
                                                value={specie.commonName}
                                             >
                                                {specie.commonName}
                                                <i>
                                                   <span className="species-latin-name">
                                                      {' '}
                                                      ({specie.latinName}
                                                   </span>
                                                </i>
                                                )
                                             </Option>
                                          ))}
                                       </Select>
                                    </Form.Item>

                                    <Form.Item name="country">
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Country"
                                          optionFilterProp="children"
                                          suffixIcon={<CaretDownOutlined />}
                                          name="country"
                                          allowClear
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'country'
                                             )
                                          }
                                          onChange={(event, label) =>
                                             this.setState({
                                                countryName:
                                                   label !== undefined
                                                      ? label.children
                                                      : '',
                                             })
                                          }
                                          filterOption={(input, option) =>
                                             option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                             0
                                          }
                                          filterSort={(optionA, optionB) =>
                                             optionA.children
                                                .toLowerCase()
                                                .localeCompare(
                                                   optionB.children.toLowerCase()
                                                )
                                          }
                                          value={this.country}
                                       >
                                          {countries_remote.map((link) => (
                                             <Option
                                                key={link.id}
                                                value={link.id}
                                             >
                                                {link.countryName}
                                             </Option>
                                          ))}
                                       </Select>
                                    </Form.Item>
                                    <Form.Item name="cultivar-type">
                                       <Select
                                          className="cultivars-dropdown-light-100"
                                          placeholder="Cultivar type"
                                          suffixIcon={<CaretDownOutlined />}
                                          name="cultivarType"
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'cultivarType'
                                             )
                                          }
                                          allowClear
                                       >
                                          <Option value="0">OP Seed</Option>
                                          <Option value="1">Hybrid seed</Option>
                                          <Option value="2">Clone</Option>
                                          <Option value="3">Graft</Option>
                                          <Option value="4">
                                             Tissue culture
                                          </Option>
                                          <Option value="5">GMO</Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item name="crop-type">
                                       <Select
                                          className="cultivars-dropdown-light-100"
                                          placeholder="Crop type"
                                          suffixIcon={<CaretDownOutlined />}
                                          name="cropType"
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'cropType'
                                             )
                                          }
                                          allowClear
                                       >
                                          <Option value="0">Vegetables</Option>
                                          <Option value="1">
                                             Roots & Tubers
                                          </Option>
                                          <Option value="2">Ornamental</Option>
                                          <Option value="3">Fruits</Option>
                                          <Option value="4">
                                             Herbs & Condiments
                                          </Option>
                                          <Option value="5">Mushrooms</Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item>
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Production system"
                                          optionFilterProp="children"
                                          suffixIcon={<CaretDownOutlined />}
                                          name="productionSystem"
                                          allowClear
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'productionSystem'
                                             )
                                          }
                                       >
                                          <Option value="0">Low tunnel</Option>
                                          <Option value="1">
                                             Walk-in tunnel
                                          </Option>
                                          <Option value="2">Greenhouse</Option>
                                          <Option value="3">
                                             Shelter structure
                                          </Option>
                                          <Option value="4">Net-house</Option>
                                          <Option value="5">
                                             Micro-garden
                                          </Option>
                                          <Option value="6">Open field</Option>
                                          <Option value="7">Laboratory</Option>
                                          <Option value="8">
                                             Organoponics
                                          </Option>
                                          <Option value="9">
                                             Other (specify in notes)
                                          </Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item>
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Production method"
                                          optionFilterProp="children"
                                          suffixIcon={<CaretDownOutlined />}
                                          name="productionMethod"
                                          allowClear
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'productionMethod'
                                             )
                                          }
                                       >
                                          <Option value="0">
                                             Conventional
                                          </Option>
                                          <Option value="1">Eco-label</Option>
                                          <Option value="2">Organic</Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item>
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Culture media"
                                          optionFilterProp="children"
                                          allowClear
                                          suffixIcon={<CaretDownOutlined />}
                                          name="cultureMedia"
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'cultureMedia'
                                             )
                                          }
                                       >
                                          <Option value="0">Soil</Option>
                                          <Option value="1">Substrate</Option>
                                          <Option value="2">
                                             Water solution (hydroponic)
                                          </Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item>
                                       <Select
                                          showSearch
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          placeholder="Target plant product"
                                          optionFilterProp="children"
                                          allowClear
                                          suffixIcon={<CaretDownOutlined />}
                                          name="targetProduct"
                                          onSelect={(value) =>
                                             handleSelectChange(
                                                value,
                                                'targetProduct'
                                             )
                                          }
                                       >
                                          <Option value="0">Leaves</Option>
                                          <Option value="1">Fruits</Option>
                                          <Option value="2">
                                             Roots & Tubers
                                          </Option>
                                          <Option value="3">Seeds</Option>
                                          <Option value="4">
                                             Inflorescence
                                          </Option>
                                          <Option value="5">Stem</Option>
                                          <Option value="6">Seedlings</Option>
                                          <Option value="7">Pot Plants</Option>
                                          <Option value="8">Cut Flowers</Option>
                                          <Option value="9">Sprouts</Option>
                                          <Option value="10">Bulb</Option>
                                          <Option value="11">
                                             Fruiting Body
                                          </Option>
                                          <Option value="12">
                                             Other (specify in notes)
                                          </Option>
                                       </Select>
                                    </Form.Item>
                                    <Form.Item>
                                       <RangePicker
                                          className="cultivars-dropdown-light-100 cultivars-input"
                                          name={'plantDate'}
                                          placeholder={[
                                             'Start Plant Date',
                                             'End Plant Date',
                                          ]}
                                          onChange={(value) =>
                                             console.log(value)
                                          }
                                       />
                                    </Form.Item>
                                    <Form.Item>
                                       Grafted <Switch />
                                    </Form.Item>
                                 </div>
                              )
                           }}
                        </Query>

                        <Form.Item>
                           <Button
                              type="primary"
                              htmlType="submit"
                              className="gmh-submit-comment-btn"
                           >
                              Search Cultivars
                              <SearchOutlined />
                           </Button>
                           <Button
                              htmlType="button"
                              onClick={this.onReset}
                              className="advanced-search-btn"
                           >
                              Reset Filters
                              <ClearOutlined />
                           </Button>
                        </Form.Item>
                     </Form>
                  </div>
               </Col>
               <Col span={16} className="advanced-search-results">
                  {this.state.validForm ? (
                     <>
                        <Query
                           query={ALL_CULTIVARS_QUERY}
                           fetchPolicy={'cache-and-network'}
                           variables={this.state.variables}
                        >
                           {({ loading, error, data }) => {
                              if (loading)
                                 return (
                                    <>
                                       <div className="search-filters">
                                          <span className="p-bold">
                                             Filters:{' '}
                                          </span>
                                          {Object.entries(
                                             this.state.variables
                                          ).map((object, i) => (
                                             <>
                                                {object[1].length !== 0 &&
                                                object[1].m49Code !== '' ? (
                                                   <>
                                                      <Tag
                                                         className="tag"
                                                         closable
                                                         closeIcon={
                                                            <CloseCircleOutlined />
                                                         }
                                                      >
                                                         <span>
                                                            {capitalizeFirstLetter(
                                                               object[0].replace(
                                                                  /([a-z0-9])([A-Z])/g,
                                                                  '$1 $2'
                                                               )
                                                            ) + ': '}
                                                         </span>
                                                         {getMenuItems(
                                                            object[0],
                                                            object[1],
                                                            this.state
                                                               .menuItems,
                                                            this.state
                                                               .countryName
                                                         )}
                                                      </Tag>
                                                   </>
                                                ) : null}
                                             </>
                                          ))}
                                       </div>
                                       <div
                                          style={{ height: '400px' }}
                                          className="search-results-map map"
                                       >
                                          <div className="spinner">
                                             <Spin
                                                size="large"
                                                tip="Loading visualization..."
                                             />
                                          </div>
                                       </div>
                                       <div>
                                          <Table
                                             className="search-results-table"
                                             columns={columns}
                                             loading={loading}
                                             pagination={{
                                                defaultPageSize: 10,
                                                showSizeChanger: true,
                                                pageSizeOptions: [
                                                   '10',
                                                   '20',
                                                   '30',
                                                ],
                                             }}
                                          />
                                       </div>
                                    </>
                                 )
                              if (error) return <LoadError />

                              const countries_data = data.performanceCultivar
                              countries_data.map((country) =>
                                 map.push({
                                    id: country.country.id,
                                    value: 1,
                                 })
                              )

                              let result = map.reduce(function (acc, curr) {
                                 let isElemExist = acc.findIndex(function (
                                    item
                                 ) {
                                    return item.id === curr.id
                                 })
                                 if (isElemExist === -1) {
                                    let obj = {}
                                    obj.id = curr.id
                                    obj.value = 1
                                    acc.push(obj)
                                 } else {
                                    acc[isElemExist].value += 1
                                 }
                                 return acc
                              }, [])

                              return (
                                 <>
                                    <div className="search-filters">
                                       <span className="p-bold">Filters: </span>
                                       {Object.entries(
                                          this.state.variables
                                       ).map((object, i) => (
                                          <>
                                             {object[1].length !== 0 &&
                                             object[1].m49Code !== '' ? (
                                                <>
                                                   <Tag
                                                      className="tag"
                                                      closable
                                                      closeIcon={
                                                         <CloseCircleOutlined />
                                                      }
                                                   >
                                                      <span>
                                                         {capitalizeFirstLetter(
                                                            object[0].replace(
                                                               /([a-z0-9])([A-Z])/g,
                                                               '$1 $2'
                                                            )
                                                         ) + ': '}
                                                      </span>
                                                      {getMenuItems(
                                                         object[0],
                                                         object[1],
                                                         this.state.menuItems,
                                                         this.state.countryName
                                                      )}
                                                   </Tag>
                                                </>
                                             ) : null}
                                          </>
                                       ))}
                                    </div>
                                    <div
                                       style={{ height: '400px' }}
                                       className="search-results-map map"
                                    >
                                       <ResponsiveChoropleth
                                          data={result}
                                          features={countries.features}
                                          colors="nivo"
                                          domain={[
                                             0,
                                             data.performanceCultivar.length,
                                          ]}
                                          unknownColor="#dfdddd"
                                          label="properties.name"
                                          valueFormat=".1s"
                                          projectionTranslation={[0.5, 0.6]}
                                          projectionRotation={[0, 0, 0]}
                                          enableGraticule={true}
                                          projectionScale={120}
                                          graticuleLineColor="#dddddd"
                                          borderWidth={0.5}
                                          borderColor="#152538"
                                          tooltip={(point) => {
                                             return <div>{point}</div>
                                          }}
                                          legends={[
                                             {
                                                anchor: 'bottom-left',
                                                direction: 'column',
                                                justify: true,
                                                translateX: 20,
                                                translateY: -10,
                                                itemsSpacing: 0,
                                                itemWidth: 94,
                                                itemHeight: 18,
                                                itemDirection: 'left-to-right',
                                                itemTextColor: '#444444',
                                                itemOpacity: 0.85,
                                                symbolSize: 18,
                                                effects: [
                                                   {
                                                      on: 'hover',
                                                      style: {
                                                         itemTextColor:
                                                            '#000000',
                                                         itemOpacity: 1,
                                                      },
                                                   },
                                                ],
                                             },
                                          ]}
                                       />
                                    </div>
                                    <br />
                                    <br />
                                    <p className="cultivar-h2">
                                       Search results ({countries_data.length})
                                    </p>
                                    <Table
                                       rowKey={(columns) => columns.key}
                                       rowSelection={rowSelection}
                                       className="search-results-table"
                                       columns={columns}
                                       loading={loading}
                                       dataSource={countries_data}
                                       onChange={(
                                          pagiation,
                                          filters,
                                          sorters
                                       ) => {
                                          document.body.scrollTop = 0 // For Safari
                                          document.documentElement.scrollTop = 0
                                       }}
                                       pagination={{
                                          defaultPageSize: 10,
                                          showSizeChanger: true,
                                          pageSizeOptions: ['10', '20', '30'],
                                       }}
                                    />
                                 </>
                              )
                           }}
                        </Query>
                     </>
                  ) : (
                     <>
                        <Alert
                           message="Fill the form on the left and click submit to view advanced search results."
                           type="info"
                           closeText="X"
                           className="no-data data-load-error"
                        />
                        {/*<Empty className="no-data" />*/}
                        {/*<LoadError />*/}
                        <div className={'search-pane'}>
                           <div className="error-pane">
                              <h1 className="not-found-search">
                                 ADVANCED SEARCH
                              </h1>
                              <p className="p-search-text">
                                 Search the HORTIVAR database for performance
                                 cultivars.
                              </p>
                              <p className="p-search-text">
                                 Use advanced filters to get fine-grained
                                 results.
                              </p>
                           </div>
                        </div>
                     </>
                  )}
               </Col>
            </Row>
         </>
      )
   }
}

export default AdvancedSearch
