import { Card, Input, Progress, Select, Switch, Tooltip } from 'antd'
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons'

import LoadError from '../../components/LoadError'
import { Query } from 'react-apollo'
import React from 'react'
import { gql } from '@apollo/client'

const { Option } = Select

const QUERY_ALL_SPECIES = gql`
   {
      species {
         id
         commonName
         latinName
      }
   }
`

class Cultivar extends React.Component {
   render() {
      return (
         <>
            <div>
               <Card className="cultivars-container">
                  <span className="header-text">
                     Cultivar{' '}
                     <span>
                        Completion Index:{' '}
                        <Progress
                           type="circle"
                           percent={1}
                           strokeColor="#356B0C"
                        />
                     </span>
                  </span>
                  <Input
                     placeholder="Common Name"
                     className="cultivars-input"
                     suffix={
                        <Tooltip title="Extra information">
                           <InfoCircleOutlined
                              style={{ color: 'rgba(0,0,0,.45)' }}
                           />
                        </Tooltip>
                     }
                     name="commonName"
                     onChange={this.props.handleChange}
                     value={this.props.commonName}
                  />
                  <Query fetchPolicy="cache-first" query={QUERY_ALL_SPECIES}>
                     {({ loading, error, data }) => {
                        if (loading)
                           return (
                              <>
                                 <Select
                                    showSearch
                                    className="cultivars-dropdown-final-100 cultivars-input"
                                    placeholder="Species (search to select)"
                                    optionFilterProp="children"
                                    name="species"
                                    disabled
                                 />
                                 <br />
                              </>
                           )
                        if (error) return <LoadError />

                        const species = data.species

                        return (
                           <div>
                              <Select
                                 showSearch
                                 className="cultivars-dropdown-final-100 cultivars-input"
                                 placeholder="Species (search to select)"
                                 optionFilterProp="children"
                                 name="species"
                                 onSelect={(value) =>
                                    this.props.handleSelectChange(
                                       value,
                                       'species'
                                    )
                                 }
                                 value={
                                    this.props.species ||
                                    'Species (search to select)'
                                 }
                                 filterOption={(input, option) =>
                                    option.children
                                       .toString()
                                       .toLowerCase()
                                       .indexOf(
                                          input.toString().toLowerCase()
                                       ) >= 0
                                 }
                                 filterSort={(optionA, optionB) =>
                                    optionA.children
                                       .toString()
                                       .toLowerCase()
                                       .localeCompare(
                                          optionB.children
                                             .toString()
                                             .toLowerCase()
                                       )
                                 }
                              >
                                 {species.map((specie) => (
                                    <Option key={specie.id} value={specie.id}>
                                       {specie.commonName}
                                       <i>
                                          <span className="species-latin-name">
                                             {' '}
                                             ({specie.latinName}
                                          </span>
                                       </i>
                                       )
                                    </Option>
                                 ))}
                              </Select>
                           </div>
                        )
                     }}
                  </Query>
                  <br />
                  <Select
                     className="cultivars-dropdown-40"
                     placeholder="Cultivar type"
                     suffixIcon={<CaretDownOutlined />}
                     name="cultivarType"
                     onSelect={(value) =>
                        this.props.handleSelectChange(value, 'cultivarType')
                     }
                     value={this.props.cultivarType || 'Cultivar type'}
                  >
                     <Option value="0">OP Seed</Option>
                     <Option value="1">Hybrid seed</Option>
                     <Option value="2">Clone</Option>
                     <Option value="3">Graft</Option>
                     <Option value="4">Tissue culture</Option>
                     <Option value="5">GMO</Option>
                  </Select>
                  <Select
                     className="cultivars-dropdown-40-right"
                     placeholder="Crop type"
                     suffixIcon={<CaretDownOutlined />}
                     name="cropType"
                     onSelect={(value) =>
                        this.props.handleSelectChange(value, 'cropType')
                     }
                     value={this.props.cropType || 'Crop type'}
                  >
                     <Option value="0">Vegetables</Option>
                     <Option value="1">Roots & Tubers</Option>
                     <Option value="2">Ornamental</Option>
                     <Option value="3">Fruits</Option>
                     <Option value="4">Herbs & Condiments</Option>
                     <Option value="5">Mushrooms</Option>
                  </Select>
                  <br />
                  <br />
                  <Select
                     className="cultivars-dropdown-40"
                     placeholder="Seed system"
                     suffixIcon={<CaretDownOutlined />}
                     name="seedSystem"
                     onSelect={(value) =>
                        this.props.handleSelectChange(value, 'seedSystem')
                     }
                     value={this.props.seedSystem || 'Seed system'}
                  >
                     <Option value="0">Direct seeding/broadcasting</Option>
                     <Option value="1">Direct seeding/in lines</Option>
                     <Option value="2">Transplanting/potted</Option>
                     <Option value="3">
                        Transplanting/non-potted (bare rooted)
                     </Option>
                     <Option value="4">Transplanting/not specified</Option>
                     <Option value="5">Direct planting</Option>
                     <Option value="6">Direct seeding/not specified</Option>
                     <Option value="7">Other (specify in notes)</Option>
                  </Select>
                  <Select
                     className="cultivars-dropdown-40-right"
                     placeholder="Record Environment"
                     suffixIcon={<CaretDownOutlined />}
                     name="recordEnvironment"
                     onSelect={(value) =>
                        this.props.handleSelectChange(
                           value,
                           'recordEnvironment'
                        )
                     }
                     value={
                        this.props.recordEnvironment || 'Record environment'
                     }
                  >
                     <Option value="0">Experimental plot</Option>
                     <Option value="1">Demonstration plot</Option>
                     <Option value="2">Farm field (small-scale)</Option>
                     <Option value="3">Farm field (large-scale)</Option>
                     <Option value="4">Home Garden</Option>
                     <Option value="5">Other (specify in notes)</Option>
                  </Select>
                  <br />
                  <br />
                  <div>
                     Grafted Cultivar{' '}
                     <Switch onChange={this.props.handleChangeBool} />
                  </div>
               </Card>
            </div>
         </>
      )
   }
}

export default Cultivar
