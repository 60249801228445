import { Carousel, Image } from 'antd';

import React from 'react';

function ImageSlider () {
    
    return (
        <>
          <Carousel autoplay>
            <div>
                <Image
                src="https://storage.cloud.google.com/fao-hortivar-review.appspot.com/images/slide_one.jpg"
                />
                <p className="login-copyright">Source: FAO of the UN. https://flic.kr/p/UcZiK7 /  All rights reserved </p>
            </div>
            <div>
            <Image
                src="https://storage.cloud.google.com/fao-hortivar-review.appspot.com/images/slide_one.jpg"
                />
                <p className="login-copyright">Source: FAO of the UN. https://flic.kr/p/UcZiK7 /  All rights reserved </p>
            </div>
            <div>
                <Image
                src="https://storage.cloud.google.com/fao-hortivar-review.appspot.com/images/slide_one.jpg"
                />
                <p className="login-copyright">Source: FAO of the UN. https://flic.kr/p/UcZiK7 /  All rights reserved </p>
            </div>
            <div>
                <Image
                src="https://storage.cloud.google.com/fao-hortivar-review.appspot.com/images/slide_one.jpg"
                />
                <p className="login-copyright">Source: FAO of the UN. https://flic.kr/p/UcZiK7 /  All rights reserved </p>
            </div>
        </Carousel>  
        </>
    );
}

export default ImageSlider;