import { Col, Row, Skeleton } from 'antd';

import React from 'react'

const GMHLoading = () => {
    document.title = 'Hortivar - Good Morning Hortivar'
    return (
        <>
        <Row justify="space-between">
                <Col span={12} style={{ paddingRight: 20, marginBottom: 30 }}>
                    <Skeleton/>
                </Col>
                <Col span={12} className="">
                    <Skeleton/>
                </Col>
                <Col span={12} style={{ paddingRight: 20, marginBottom: 30 }}>
                    <Skeleton/>
                </Col>
                <Col span={12} className="">
                    <Skeleton/>
                </Col>
                <Col span={12} style={{ paddingRight: 20, marginBottom: 30 }}>
                    <Skeleton/>
                </Col>
                <Col span={12} className="">
                    <Skeleton/>
                </Col>
                <Col span={12} style={{ paddingRight: 20, marginBottom: 30 }}>
                    <Skeleton/>
                </Col>
                <Col span={12} className="">
                    <Skeleton/>
                </Col>
                <Col span={12} style={{ paddingRight: 20, marginBottom: 30 }}>
                    <Skeleton/>
                </Col>
                <Col span={12} className="">
                    <Skeleton/>
                </Col>
        </Row>
        </>
    )
};

export default GMHLoading;