import { Col, Row } from 'antd';

import React from 'react';

function Network() {
  document.title = 'Network - Hortivar'
  return (
    <>
      <Row>
          <Col className="sidebar" span={6}></Col>
          <Col span={18}>
              <p>Network</p>
          </Col>
      </Row>
    </>
  );
}

export default Network;