import { Card, Input, Progress, Select, Tooltip } from "antd";
import { CaretDownOutlined, InfoCircleOutlined } from "@ant-design/icons";

import LoadError from "../../components/LoadError";
import { Query } from "react-apollo";
import React from "react";
import { gql } from "@apollo/client";

const { Option } = Select;

const QUERY_ALL_COUNTRIES = gql`
  {
    countries {
      id: m49Code
      countryName
    }
  }
`;

class GeoBounderies extends React.Component {
  render() {
    return (
      <>
        <Card className="cultivars-container">
          <span className="header-text">
            Geo-Boundaries{" "}
            <span>
              Completion Index:{" "}
              <Progress type="circle" percent={25} strokeColor="#356B0C" />
            </span>
          </span>
          <Query query={QUERY_ALL_COUNTRIES} fetchPolicy="cache-first">
            {({ loading, error, data }) => {
              if (loading) return <div>
                <Select
                    showSearch
                    className="cultivars-dropdown-final-100 cultivars-input"
                    placeholder="Country"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownOutlined />}
                    name="country"
                    disabled
                >
                </Select>
              </div>;
              if (error) return <LoadError />;

              const countries = data.countries;

              return (
                <div>
                  <Select
                    showSearch
                    className="cultivars-dropdown-final-100 cultivars-input"
                    placeholder="Country"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownOutlined />}
                    name="country"
                    onSelect={(value) =>
                      this.props.handleSelectChange(value, "country")
                    }
                    onChange={this.props.handleChangeCountryName}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    value={this.props.country}
                  >
                    {countries.map((link) => (
                      <Option key={link.id} value={link.id}>
                        {link.countryName}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            }}
          </Query>
          <Input
            placeholder="GPS Coordinates"
            className="cultivars-input"
            suffix={
              <Tooltip title="Extra information">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
            name="cordinates"
            onChange={this.props.handleChange}
            value={this.props.cordinates}
          />
          <p className="input-side-text">
            <Input
              placeholder="Altitude"
              className="cultivars-input-50"
              suffix={
                <Tooltip title="Extra information">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              name="altitude"
              onChange={this.props.handleChange}
              value={this.props.altitude}
            />{" "}
            <span>
              in <strong>m</strong> above mean sea level
            </span>
          </p>
        </Card>
      </>
    );
  }
}

export default GeoBounderies;
