import { Col, Row } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import React from 'react'

const ViewCultivar = () => {
   return (
      <>
         <Row className="about-page">
            <Col className="" span={10}>
               <Row>
                  <Col span={12}>
                     <div>
                        <p className="about-hortivar-p-bold">
                           FAO of the UN
                           <br />
                           Hortivar (NSPLD)
                           <br />
                           Viale delle Terme di Caracalla
                           <br />
                           00153, Rome, Italy
                           <br />
                           <br />
                           hortivar@fao.org
                        </p>
                     </div>
                  </Col>
                  <Col span={12}>
                     <div className="about-links">
                        <a href="#aboutHortivar">
                           <CaretRightOutlined /> About HORTIVAR
                        </a>
                        <br />
                        <a href="#contactUs">
                           <CaretRightOutlined /> Contact Us
                        </a>
                        <br />
                        <a href="#credits">
                           <CaretRightOutlined /> Credits
                        </a>
                     </div>
                  </Col>
               </Row>
            </Col>
            <Col className="" span={14}>
               <div className="contact-right">
                  <p className="cultivar-h2" id="aboutHortivar">
                     About HORTIVAR
                  </p>
                  <p className="about-hortivar-p">
                     Hortivar is FAO´s database on the performances of
                     horticultural cultivars over the world. Hortivar is also a
                     platform for exchange of know-how among scientists and a
                     tool to safeguard, retrieve and exchange information
                     related to horticulture.
                  </p>
                  <p className="about-hortivar-p">
                     Information can be retrieved regarding six groups of crops:
                     fruits, vegetables, root and tuber crops, herbs and
                     condiments, ornamentals and mushrooms.
                  </p>
                  <p className="about-hortivar-p">
                     The database has two primary functions which are
                     interdependent: data retrieval and data entry. Access and
                     use of the database is free of charge.
                  </p>
                  <p className="about-hortivar-p">
                     Hortivar serves as a standard methodology for data
                     collection and record keeping on the performances of
                     horticulture cultivars. It is a a powerful search engine
                     for easy retrieval and comparison of information. It is
                     also a template for educational purposes and a gateway to
                     horticulture knowledge/statistics.
                  </p>
                  <p className="about-hortivar-p">
                     Hortivar addresses the needs of producers, public and
                     private sector, seed companies and horticultural research
                     centres for information management related to horticultural
                     crop cultivars in different agro-climatic environments and
                     allows users to identify cultivars and cropping practices
                     adapted to their specific requirements and environment.
                  </p>
                  <p className="about-hortivar-p">
                     While FAO maintains and develops this programme and
                     software, it relies on its members to feed it with
                     information either as individual or as institutional
                     partners. Hortivar is not a static programme but it is
                     continuously being adjusted to meet the requirements of its
                     users.
                  </p>

                  <div>
                     <p className="cultivar-h2" id="credits">
                        Credits
                     </p>
                     <p className="about-hortivar-p">
                        Hortivar, is a FAO database on the performances of
                        horticulture cultivars and a knowledge management tool,
                        is developed by Crop diversification for improved
                        livelihoods, health and income thematic program, Plant
                        Production and Protection Division within a project
                        sponsored by the Government of Belgium (GCP/INT/697/BEL
                        which was upgraded to a multi-donor project,
                        MTF/GLO/697/MUL)
                     </p>
                     <div className="tabbed-space">
                        <p>
                           Project coordinator:
                           <br />
                           J. YongHo
                           <br />
                           T. Bruno
                           <br />
                           R. Jayne
                           <br />
                        </p>

                        <p>
                           Hortivar desk office: <br />
                           -
                           <br />
                           <br />
                           System development and programming:
                           <br />
                           Mwiza Simbeye
                           <br />
                           Peter Maleh
                        </p>
                     </div>
                     <p className="about-hortivar-p">
                        Within FAO, the following Groups and Services are
                        cooperating on the development of the Hortivar database
                        and website:
                     </p>
                     <li>Nutrition and Consumer Protection Division</li>
                     <li>Plant Production and Protection Division</li>
                     <li>Knowledge Information Systems Branch</li>
                     <li>Field Projects</li>
                     <li>FAO Networks</li>
                     <li>FAO Field Offices</li>
                     <br />
                     <p className="about-hortivar-p">
                        Scientists from horticulture research institutes and
                        universities reviewed the database and provided valuable
                        comments and suggestions for its design and improvement.
                     </p>
                  </div>
               </div>
            </Col>
         </Row>
      </>
   )
}

export default ViewCultivar
