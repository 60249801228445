import { Col, Row } from 'antd';

import Moment from 'moment';
import React from 'react';
import { CircleFlag } from 'react-circle-flags'
import CalendarHeatmap from 'react-calendar-heatmap';
import Gravatar from 'react-gravatar'

const LeftPanel = ({data}) => {
    Moment.locale('en');
    return (
      <div id="view-cultivar-left-panel">
        <p>Crop Details</p>
        <Row>
          <Col span={11}>
            <div className="cultivar-detail-card">
              <Row>
                <Col span={12} className="card-detail-left">
                  <p className="crop-name">{data.performanceCultivar[0].species.commonName}</p>
                  <p>{data.performanceCultivar[0].species.latinName}</p>
                </Col>
                <Col span={12} className="card-detail-right">
                  <p>Crop Category: {data.performanceCultivar[0].cropType}</p>
                  <p>Entry ID: {data.performanceCultivar[0].id}</p>
                  <p>Country: {data.performanceCultivar[0].country.countryName} <CircleFlag countryCode={data.performanceCultivar[0].country.isoAlpha2Code.toLowerCase()} height="20" /> </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={13}>

          </Col>
        </Row>
        <Row className="user-information-panel">
            <Col span={4}>
              <div className="user-avatar">
                <Gravatar email={data.performanceCultivar[0].user.email} size={30} className="gmh-profile-logo"/>
              </div>
            </Col>
            <Col span={20}>
              <p className="user-fullname">
                {data.performanceCultivar[0].user.firstName} {data.performanceCultivar[0].user.lastName}
              </p>
            </Col>
        </Row>
        <Row className="tableau-timelines">
            <Col>
            <p>Harvest months: <span className="tableau-date-range"> {Moment(data.performanceCultivar[0].startHarvestDate).format('MMMM')} - {Moment(data.performanceCultivar[0].endHarvestDate).format('MMMM')}</span></p>
            <CalendarHeatmap
              values={[
                { date: data.performanceCultivar[0].startHarvestDate, count: 1 },
                { date: data.performanceCultivar[0].endHarvestDate, count: 10 },
              ]}
            />
            </Col>
        </Row>
        <Row className="tableau-timelines">
            <Col>
            <p>Planting dates: <span className="tableau-date-range"> {Moment(data.performanceCultivar[0].plantDate).format('MMMM')} - {Moment(data.performanceCultivar[0].startHarvestDate).format('MMMM')}</span></p>
            <CalendarHeatmap
              values={[
                { date: data.performanceCultivar[0].plantDate, count: 1 },
                { date: data.performanceCultivar[0].endHarvestDate, count: 1 },
              ]}
            />
            </Col>
        </Row>
        <Row className="tableau-timelines">
            <Col>
            <p>Crop yield: <span className="tableau-date-range"> {data.performanceCultivar[0].unitYield}/{data.performanceCultivar[0].unit}</span></p>
            </Col>
        </Row>
        <Row className="url-embeds">
            <Col>
            <p>External URL widget</p>
            </Col>
        </Row>
        <Row className="url-embeds">
            <Col>
            <p>Stats / Widget / short</p>
            </Col>
        </Row>
      </div>
    )
  }

export default LeftPanel;
